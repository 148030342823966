import { Image } from '@chakra-ui/image';
import { Box, Flex, Grid, HStack, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import Title from '../Components/Title';
import Triptych from '../Layouts/Triptych';
import RichContent from '../Components/RichContent'
import BgTitleWired from '../Components/BgTitleWired';

const WoWeAre = ({
    btnNext,
    btnPrev,
    data,
    handleGoHome
}) => {

    const Body = () => (
        <Grid
            templateColumns={{
                base: '100%',
                lg: '10% 45% 45%'
            }}
            h='100%'

            maxH={{ base: 'calc(100vh - 18px)', lg: 'calc(100vh - 98px)' }}
            overflowY='scroll'

            className='noscrollbar'
        >
            <Box
                borderRight='solid 1px'
                borderRightColor='gray.100'
                minH='100%'
            >
                <BgTitleWired>
                    {data.fields.backgroundTitle}
                </BgTitleWired>
            </Box>
            <Box
                borderRight='solid 1px'
                borderRightColor='gray.100'
                order={{ base: 1, lg: 0 }}
            >
                <Flex
                    minH='100%'
                    alignItems='flex-start'
                    position='relative'
                    zIndex='banner'
                >
                    <Stack
                        spacing={6}
                        mt={12}
                        px={{ base: 6, lg: 0 }}
                        pt={{ base: 0, lg: 32 }}
                    >
                        <Title>
                            {data.fields.title}
                        </Title>
                        <Box
                            pl={{ base: 0, lg: 10 }}
                        >
                            <HStack
                                textTransform='uppercase'
                                fontWeight='bold'
                                letterSpacing='widest'
                                fontSize={{ base: '8px', lg: '10px' }}
                            >

                                <Text>
                                    {data.fields.dataPrimary.subTitle}
                                </Text>

                            </HStack>
                        </Box>
                        <Box
                            pr={{ base: 0, lg: 24 }}
                            pl={{ base: 0, lg: 10 }}
                        >
                            <Box
                                fontSize={{ base: 'sm', lg: 'sm' }}
                            >
                                <RichContent data={data.fields.contentPrimary} />
                            </Box>
                        </Box>

                    </Stack>

                </Flex>
            </Box>
            <Box
                px={{ base: 6, lg: 0 }}
                pt={{ base: 32 }}
                pr={{ base: 0, lg: 10 }}
                mt={{ base: 0, lg: 12 }}

                position='relative'
                zIndex='banner'
            >
                <Flex
                    minH='100%'
                    alignItems={{ base: 'flex-start' }}
                    pl={{ base: '20px', lg: '30px' }}>
                    <Box
                        bgColor='brand.blue'
                        maxW={{ base: '220px', lg: '360px' }}
                        transform={{ base: 'translate3d( 0, 0, 0)', lg: 'translate3d( 0px, 30px, 0)' }}

                    >
                        <Image
                            src={
                                data.fields.primaryImage
                                    && data.fields.primaryImage.fields
                                    && data.fields.primaryImage.fields.file ?
                                    `${data.fields.primaryImage.fields.file.url}?w=962&h=800&fit=fill&fm=webp&q=60`
                                    : null}
                            transform={{ base: 'translate3d( -20px, -20px, 0)', lg: 'translate3d( -30px, -30px, 0)' }}
                            loading='lazy'
                            maxW='100%'
                        />
                    </Box>
                </Flex>
            </Box>
        </Grid>
    )

    return (
        <Box
            position='relative'
        >
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                col3={btnNext}
                logo={data.fields.displayLogo}
                handleGoHome={handleGoHome}
            />
        </Box>
    )
}

export default WoWeAre